<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/task.png" alt />
        <span class="center">服务包管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">服务包列表</span>
      </div>
      <div style="display: flex; align-items: center">
        <div style="display: flex; align-items: center">
          <span class="seach_text">关键字:</span>
          <el-input
            placeholder="请输入服务包名称"
            v-model="keywords"
            @input="loadData('seath')"
            size="mini"
            style="margin-right: 10px; width: 250px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
        <el-button style="margin-left:20px" type="primary" size="small" @click="addProject()">新建</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="服务包编号">
        <template slot-scope="scope">
          <span class="clickColor" @click="goDetail(scope.row.code)">{{ scope.row.code }}</span>
        </template>
      </el-table-column>

      <el-table-column label="服务包名称">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>

      <el-table-column label="工资包名称">
        <template slot-scope="scope">
          <span v-if="scope.row.taskName">{{ scope.row.taskName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发薪企业数">
        <template slot-scope="scope">
          <span v-if="scope.row.dispatchNum">{{ Math.ceil(scope.row.dispatchNum/2) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结算金额">
        <template slot-scope="scope">
          <span v-if="scope.row.totalSalary">{{ scope.row.totalSalary }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发包时间">
        <template slot-scope="scope">
          <span v-if="scope.row.creatTime">{{ scope.row.creatTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.canCancel"
            @click="gorevoke(scope.row.code,scope.row.name)"
            type="text"
            style="color:red;"
          >撤销</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const totalTaskList = api()('totalTask.list.json');
export const totalTaskCancel = api()('totalTask.cancel.json');

export default {
  data () {
    return {
      tableData: [

      ],
      dialogTitle: '',
      keywords: '',
      pageSize: 10,
      pageNumber: 1,
      total: 0,
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    formatText (row) {

      const map = {
        0: '已撤销',
        25: '审核中',
        50: '被驳回',
        75: '复核中',
        100: '已完成',
      };

      return map[row];
    },

    addProject () {
      this.$router.push({
        path: '/system/service/add',
      });
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData (seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        keywords: this.keywords
      };
      totalTaskList(params).then((res) => {
        for (let i = 0; i < res.list.length; i++) {
          res.list[i].passShow = false
        }
        this.tableData = res.list;
        console.log(this.tableData)
        this.total = res.total;
      });
    },
    goShow (code) {

      this.tableData.forEach(it => {
        if (it.code === code) {
          console.log(it)
          it.passShow = !it.passShow
        }
      })

    },
    goedit (code) {
      this.$router.push({
        path: '/system/service/add',
        query: { code },
      });
    },
    gorevoke (code, name) {
      console.log(code);
      this.$confirm('', '', {
        title: '撤销服务包',
        message: `是否撤销${name}服务包`,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        console.log(res);
        const params = {
          code: code
        };
        totalTaskCancel(params).then(() => {
          this.$message({
            type: 'success',
            message: '撤销成功'
          });
          this.loadData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消撤销'
        });
      });

    },
    seeRevoke (code) {
      console.log(code);
      this.$alert('1111111111', '查看撤销原因', {
        confirmButtonText: '确定',
        showClose: false,
        callback: () => {
          this.$message({
            type: 'info',
            message: '关闭撤销'
          });
        }
      });
    },
    goDetail (code) {
      this.$router.push({
        path: '/system/service/detail',
        query: { code },
      });
    }
  },
}
</script>
<style lang="scss" scope>
.showImgDialog .imgBox {
  display: flex;
}
.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}
.font-img {
  font-size: 26px;
  cursor: pointer;
  color: cadetblue;
}
</style>